<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">积分比例配置</div>
		</div>
		<div class="flex">
			<div></div>
			<el-button @click="addshow=true;addtype=1;resource='1'">添加配置</el-button>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="userType" label="用户类型" width="300">
                     <template slot-scope="scope">
                        <span >{{scope.row.userType == 0 ?  '企业会员' : scope.row.userType == 1 ? '供应商' : '分销员/游客'}}</span>
                    </template>
                </el-table-column>
				<el-table-column prop="userName" label="用户名称"></el-table-column>
				<el-table-column prop="rechargePre" label="充值比例（1元：N积分）">
					<template slot-scope="scope">
                        <span >{{scope.row.userType == 1 ? '-' : scope.row.rechargePre}}</span>
					</template>
				</el-table-column>
                <el-table-column prop="drawPre" label="提现比例（1积分：N元）" >
					<template slot-scope="scope">
                        <span >{{scope.row.userType == 0 ?  '-' :scope.row.drawPre}}</span>
					</template>
				</el-table-column>
                <el-table-column prop="updateTime" label="操作时间" ></el-table-column>
				<el-table-column  label="操作" width="300">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="updataclick(scope.$index, data)" type="text" size="small">
							修改
						</el-button>
						<el-button v-if="scope.row.allowDelete == 1" @click.native.prevent="deleteList(scope.$index, data)" type="text" size="small">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 新增修改弹窗 -->
		<el-dialog :visible.sync="addshow" width="700px" :before-close="handleClose" :title="addtype == 1 ? '添加' : '修改'">
			<el-form class="form_box" :model="sizeForm" label-position='right' ref="sizeForm"  size="small">
				<el-form-item  >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>用户类型：</p>
							<el-select v-model="sizeForm.userType" v-if="sizeForm.userType != 2" clearable style="width:350px;"  @change="chooseType($event)" :disabled="sizeForm.userType == 2">
								<template v-for="item in userData">
									<el-option v-if="item.type != 2" :key="item.type" :label="item.typeName" :value="item.type"> </el-option>
								</template>
							</el-select>
							<el-select v-model="sizeForm.userType" v-else clearable style="width:350px;" :disabled="true">
								<template v-for="item in userData">
									<el-option   :key="item.type" :label="item.typeName" :value="item.type"> </el-option>
								</template>
							</el-select>
						</div>
					</template>
				</el-form-item>
               	<el-form-item  >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>用户名称：</p>
							<el-select  v-model="sizeForm.userId" clearable style="width:350px;" :disabled="sizeForm.userType == 2">
								<el-option v-for="item in users" :key="item.userId" :label="item.username" :value="item.userId"> </el-option>
							</el-select>
						</div>
					</template>
				</el-form-item>
				<el-form-item  >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>充值比例：</p>
							<el-input class="numberInput" type="number" style="width:210px;" min="0" :disabled="sizeForm.userType == 1" oninput="if(value.length>5)value=value.slice(0,5)"  v-model="sizeForm.rechargePre"></el-input>
                   	 		<span class="number_tip">填写2则2元兑换1积分</span>
						</div>
					</template>
				</el-form-item>
				<el-form-item  >
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:130px;text-align:right;margin-right:10px;"><span style="color:red">*</span>提现比例：</p>
							<el-input class="numberInput" type="number" style="width:210px;" min="0" oninput="if(value.length>5)value=value.slice(0,5)" :disabled="sizeForm.userType == '0'"  v-model="sizeForm.drawPre"></el-input>
                   	 		<span class="number_tip">填写2则2元兑换1积分</span>
						</div>
					</template>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:center;">
					<el-button v-if="addtype == 2" type="primary"  @click.native.prevent="onUpdata('sizeForm')">保存</el-button>
					<el-button v-if="addtype == 1" type="primary"  @click.native.prevent="submitForm('sizeForm')">保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		integralpreconfList,integralpreconfAdd,integralpreconfUpdate,integralpreconfParams,integralpreconfDelete
	} from "@/api/platform/index";
	export default {
		name: "proportionSet",
		data() {
			return {
				resource: "1",
				data: [],
				userData:[],
				users:[],
                supplierList:[],
				addshow: false,
				sizeForm: {
                    id:'',
                    drawPre:'',
                    rechargePre:'',
                    userId:'',
                    userType:'',
				},
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				addtype: '',
				id: 0,
			};
		},
		mounted() {
			let that = this;
			that.list();
			that.getUser();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				integralpreconfList({
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},

			
            // 获取比例用户列表
            getUser(){
                integralpreconfParams({}).then(res => {
					if (res.code == 200) {
                    	this.userData = res.data.params;
                    }
                })
            },

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},

			//删除
			deleteList(cur, tab) {
				this.$confirm('您确定要删除该积分比例配置?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					integralpreconfDelete(tab[cur].id).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.page = 1
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					})
				})
			},
			
			//关闭提醒
			handleClose(done) {
				this.sizeForm = {
					id:'',
					drawPre:'',
					rechargePre:'',
					userId:'',
					userType:'',
				}
				this.addshow = false
			},

			//编辑按钮点击
			updataclick(i,tab){
				let that = this;
				that.users = that.userData[tab[i].userType].users;
				this.sizeForm = {
                    id:tab[i].id,
                    drawPre:tab[i].drawPre,
                    rechargePre:tab[i].rechargePre,
                    userId:tab[i].userId,
                    userType: tab[i].userType
				}
				this.id = tab[i].id
				this.addtype = 2
				this.addshow = true
			},

			// 用户类型选择	用户名称联动
			chooseType(e){
				let that = this;
				if(e != null && e.toString().length > 0){
					that.sizeForm.drawPre = '';
					that.sizeForm.rechargePre = '';
					that.sizeForm.userType = e;
					that.users = that.userData[e].users;
					that.sizeForm.userId = '';
				}else{
					that.userId = '';
					that.users = [];
				}
			},

			//添加提交
			submitForm(formName) {
				let that = this;
				console.log(that.$refs[formName].model);
				if(that.addshow){
					that.$refs[formName].validate((valid) => {
						if (valid) {
							if(that.$refs[formName].model.userType != null && that.$refs[formName].model.userType.toString().length > 0){
								integralpreconfAdd({
									drawPre: that.$refs[formName].model.drawPre,
									rechargePre: that.$refs[formName].model.rechargePre,
									userId: that.$refs[formName].model.userId,
									userType: that.$refs[formName].model.userType,
								}).then(res=>{
									if(res.code == 200){
										that.$message.success('添加成功');
										that.sizeForm = {
											drawPre:'',
											rechargePre:'',
											userId:'',
											userType:'',
										}
										that.addshow = false
										that.page = 1
										that.list();
									}else{
										that.$message.error(res.msg);
									}
								}).catch(err => {
									that.$message.error(err.msg);
								})
							}else{
								that.$message.warning('请先选择用户类型');
							}
						} else {
							return false;
						}
					});
				}
			},

			//编辑提交
			onUpdata(formName){
				let that = this;
				console.log(that.$refs[formName].model);
				that.$refs[formName].validate((valid) => {
					if (valid) {
						if(that.$refs[formName].model.userType != null && that.$refs[formName].model.userType.toString().length > 0){
							if(that.$refs[formName].model.drawPre >= 0 ){
								if(that.$refs[formName].model.rechargePre >= 0){
									integralpreconfUpdate({
										id: that.$refs[formName].model.id,
										drawPre: that.$refs[formName].model.drawPre,
										rechargePre: that.$refs[formName].model.rechargePre,
										userId: that.$refs[formName].model.userId,
										userType: that.$refs[formName].model.userType,
									}).then(res=>{
										if (res.code == 200) {
											that.$message.success('修改成功');
											that.sizeForm = {
												id:'',
												drawPre:'',
												rechargePre:'',
												userId:'',
												userType:'',
											}
											that.addshow = false
											that.page = 1
											that.list();
										} else {
											that.$message.error(res.msg);
										}
									}).catch(err => {
										that.$message.error(err.msg);
									})
								}else{
									that.$message.warning('充值比例不能为负数');
								}
							}else{
								that.$message.warning('提现比例不能为负数');
							}
						}else{
							that.$message.warning('请先选择用户类型');
							return;
						}
					} else {
						return false;
					}
				});
			},
		}
	}
</script>

<style scoped>
	.table-content {margin-top: 20px;}
	.active {color: #049EC4;}
	.form_box{padding:0 50px;}
	.avatar{width:150px;}
	.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
	.flex{display:flex;align-items:center;justify-content:space-between;}

	.meunTop{display:flex;align-items:center;margin-bottom:20px;}
	.meunLine{width:3px;height:20px;background-color:#FFA218;}
	.meunTitle{font-size:20px;margin-left:10px;}
    .el-input__inner{
        -webkit-appearance: none !important;
        -moz-appearance: textfield !important;
    }
    .number_tip{
        font-size: 12px;
        margin-left: 10px;
        color: #909090;
    }
</style>
